import React from "react";

import { makeStyles } from '@material-ui/core/styles'

import myResume from '../../static/Julia PottingerResume.jpg'
import myResumePDF from '../../content/assets/Resume-Julia-Pottinger.pdf'
import { isTablet } from "react-device-detect"
import { Button } from "@material-ui/core"

import "react-pdf/dist/Page/AnnotationLayer.css"

const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.up('xs')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '13%',
            alignItems: 'center',
            width: '92%',
            height: '70%',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '18%',
            alignItems: 'center',
            width: '88%',
            height: '70%',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '7%',
            alignItems: 'center',
            width: '90%',
            height: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '7%',
            alignItems: 'center',
            width: '60%',
            height: '70%',
        },
        paddingBottom: '0%',
        marginBottom: '0%',
        flexGrow: 0,
        minHeight: '90vh',
        "& canvas": {
            boxShadow: "0 15px 15px 0 grey !important",
        },
        justifyContent: 'center',
    },
    contentTablet: {
        [theme.breakpoints.up('xs')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '17%',
            alignItems: 'center',
            width: '80%',
            height: '70%',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '17%',
            alignItems: 'center',
            width: '80%',
            height: '70%',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10%',
            alignItems: 'center',
            width: '80%',
            height: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '8%',
            alignItems: 'center',
            width: '85%',
            height: '70%',
        },
        paddingBottom: '0%',
        marginBottom: '0%',
        flexGrow: 0,
        minHeight: '90vh',
        "& canvas": {
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.7) !important",
        },
        justifyContent: 'center',
    },
    downloadButton: {
        backgroundColor: '#43A6C6',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#2E8BC0',
            color: '#FFF'
        },
        marginBottom: '5%',

        [theme.breakpoints.up('xs')]: {
            marginLeft: '30%'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '33%'
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '40%'
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '42%'
        },
    },
    pdfPage: {
        justifyContent: 'center',
    },
    headerImg: {
        boxShadow: "0 15px 15px 5px grey !important",
    }
})
)

const MyPDFViewer = () => {
    const classes = useStyles();
    if (isTablet) {
        return (
            <div className={classes.contentTablet}>
                <img src={myResume} className={classes.headerImg} width='100%' height='20%' alt='' />
                <Button title="Download" placement="top" className={classes.downloadButton} variant="contained" href={myResumePDF}
                    download='JuliaPottingerResume.pdf'>
                    Download
                </Button>
            </div>
        )
    } else {
        return (
            <div className={classes.content}>
                <img src={myResume} className={classes.headerImg} width='100%' height='20%' alt='' />
                <Button title="Download" placement="top" className={classes.downloadButton} variant="contained" href={myResumePDF}
                    download='JuliaPottingerResume.pdf'>
                    Download
                </Button>
            </div>
        );
    }
}

export default MyPDFViewer