import React from "react"
import MyPdfViewer from '../components/resumePDF'
import SEO from "../components/seo"

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ResumeSideBar from '../components/resumeSidebar'

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 568,
            md: 760,
            lg: 960,
            xl: 1200,
        }
    }
});

const Resume = () => (
    <MuiThemeProvider theme={theme}>
        <div>
            <ResumeSideBar />
            <SEO title='Resume' />
            <MyPdfViewer />
        </div>
        <div
            style={
                {
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingBottom: '2%',
                }
            }>
            © {new Date().getFullYear()} Julia Pottinger - All Rights Reserved
      </div>
    </MuiThemeProvider>
);

export default Resume