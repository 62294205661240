import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button";
import Popover from '@material-ui/core/Popover';

//Icons
import YouTubeIcon from '@material-ui/icons/YouTube';
import MenuIcon from '@material-ui/icons/Menu';
import CodeIcon from '@material-ui/icons/Code';
import MailIcon from '@material-ui/icons/Mail';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

import HubspotSubscribe from '../components/subscribeHubspot'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            // display: 'none',
            width: '100%',
            backgroundColor: 'rgba(67, 129, 168)',
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '1%',
            paddingLeft: '4%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            backgroundColor: 'rgba(67, 129, 168)',
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '1%',
            paddingLeft: '4%',
        },
    },
    drawerList: {
        display: 'flex',
        whiteSpace: "nowrap",
        color: 'white',
        textAlign: 'right',
        marginLeft: 'auto',
        paddingRight: '4%',
    },
    drawerMenuItemList: {
        '&:hover': {
            color: '#16D8E4',
        },
    },
    drawerMenuItemText: {
        paddingLeft: theme.spacing(2),
        fontSize: '1.2rem',
        '&:hover': {
            color: '#16D8E4',
        },
    },
    appBar: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: drawerWidth,
            backgroundColor: '#4381A8',
            color: 'white',
        },
        [theme.breakpoints.up('md')]: {
            // width: '100%',
            // marginLeft: drawerWidth,
            // backgroundColor: '#4381A8',
            // color: 'white',
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
    },
    menuButton: {
        marginLeft: 'auto',
        color: 'white',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: '#4381A8',
        border: 'none',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    large: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        height: 200,
        width: 200,
    },
    small: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        [theme.breakpoints.up('xs')]: {
            height: theme.spacing(7),
            width: theme.spacing(7),
        },
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing(8),
            width: theme.spacing(8),
        },
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(10),
            width: theme.spacing(10),
        },
        [theme.breakpoints.up('lg')]: {
            height: theme.spacing(10),
            width: theme.spacing(10),
        },
    },
    avatarMenu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 25,
        cursor: 'pointer',
    },
    avatarAppBar: {
        paddingLeft: 25,
        paddingBottom: 10,
        marginTop: 10,
        cursor: 'pointer',
    },
    nested: {
        paddingLeft: theme.spacing(3),
        justifyContent: 'center',
    },
    menuItemText: {
        paddingLeft: theme.spacing(2)
    },
    menuItemList: {
        color: 'white',
        '&:hover': {
            backgroundColor: '#2E8BC0',
            color: '#FFF'
        }
    },
    mediaContactList: {
        justifyContent: 'center',
        marginTop: 25
    },
    mediaContactListItem: {
        color: 'white',
        padding: 10,
        '&:hover': {
            color: 'white'
        },
    },
    mediaContactListItemAppBarStyle: {
        [theme.breakpoints.up('md')]: {
            fontSize: 'large',
        },
    },
    mediaContactListItemAppBar: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: '20%',
            paddingRight: '7%',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '25%',
            paddingRight: '10%',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '25%',
            paddingRight: '10%',
        },
    },
    mediaContactListAppBar: {
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('xs')]: {
            paddingLeft: '7%',
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '10%',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '10%',
        },

    },
    btn: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '12px',
            marginLeft: '30%',
            marginTop: '7%'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            marginLeft: '30%',
            marginTop: '7%'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '22px',
            marginLeft: '20%',
            marginTop: '16%'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '26px',
            marginLeft: '30%',
            marginTop: '7%'
        },
        backgroundColor: '#16D5DF',
        color: 'black',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#16D5DF',
            color: 'black'
        },

        boxShadow: '0 3px 3px rgba(0, 0, 0, 0.4)',
    },
    'subscribe': {
        backgroundColor: 'transparent'
    }
}));

function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const drawer = (
        <div className={classes.toolbar}>
            <AppBar className={classes.drawer}>
                <div className={classes.avatarMenu}>
                    <Avatar alt="Julia Pottinger" src={require('../../content/assets/profile-pic-real.jpg')} className={classes.small} onClick={event => { navigate("/") }} />
                </div>
                <List className={classes.drawerList}>
                    <ListItem button key={'articles'} className={classes.drawerMenuItemList} onClick={event => { navigate("/articles") }}>
                        <div className={classes.drawerMenuItemText}>
                            Articles
                        </div>
                    </ListItem>
                    <ListItem button key={'youtube-content'} className={classes.drawerMenuItemList} onClick={event => window.open('https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1', '_blank')} >
                        <div className={classes.drawerMenuItemText}>
                            Youtube Content
                        </div>
                    </ListItem>
                    <ListItem button key={'free-courses'} className={classes.drawerMenuItemList} onClick={event => { navigate("/courses/") }}>
                        <div className={classes.drawerMenuItemText}>
                            Courses
                        </div>
                    </ListItem>
                    {/* <ListItem button key={'resume'} className={classes.drawerMenuItemList} onClick={event => { navigate("/resume/") }}>
                        <div className={classes.drawerMenuItemText}>
                            Resumè
                        </div>
                    </ListItem> */}
                    <ListItem button key={'contact'} className={classes.drawerMenuItemList} onClick={event => { navigate("/contact/") }}>
                        <div className={classes.drawerMenuItemText}>
                            Contact
                        </div>
                    </ListItem>
                </List>
            </AppBar>
        </div >
    );

    const drawerSide = (
        <div className={classes.toolbar}>
            <div className={classes.avatarMenu}>
                <Avatar alt="Julia Pottinger" src={require('../../content/assets/profile-pic-real.jpg')} className={classes.large} onClick={event => { navigate("/") }} />
            </div>
            <List className={classes.nested}>
                <ListItem button key={'articles'} className={classes.menuItemList} onClick={event => { navigate("/articles") }}>
                    <AssignmentIcon />
                    <ListItemText primary='Articles' className={classes.menuItemText} />
                </ListItem>
                <ListItem button key={'youtube-content'} className={classes.menuItemList} onClick={event => window.open('https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1', '_blank')} >
                    <YouTubeIcon />
                    <ListItemText primary='Youtube Content' className={classes.menuItemText} />
                </ListItem>
                <ListItem button key={'free-courses'} className={classes.menuItemList} onClick={event => { navigate("/courses/") }}>
                    <CodeIcon />
                    <ListItemText primary='Courses' className={classes.menuItemText} />
                </ListItem>
                {/* <ListItem button key={'resume'} className={classes.menuItemList} onClick={event => { navigate("/resume/") }}>
                    <CreateIcon />
                    <ListItemText primary='Resumè' className={classes.menuItemText} />
                </ListItem> */}
                <ListItem button key={'contact'} className={classes.menuItemList} onClick={event => { navigate("/contact/") }}>
                    <MailIcon />
                    <ListItemText primary='Contact' className={classes.menuItemText} />
                </ListItem>
            </List>

            <Box display="flex" flexDirection="row" className={classes.mediaContactList}>
                <Box className={classes.mediaContactListItem}>
                    <YouTubeIcon onClick={event => window.open('https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1', '_blank')} />
                </Box>
                <Box className={classes.mediaContactListItem}>
                    <TwitterIcon onClick={event => window.open('https://twitter.com/ailuj876', '_blank')} />
                </Box>
                <Box className={classes.mediaContactListItem}>
                    <GitHubIcon onClick={event => window.open('https://github.com/jpott77', '_blank')} />
                </Box>
                <Box className={classes.mediaContactListItem}>
                    <LinkedInIcon onClick={event => window.open('https://www.linkedin.com/in/julia-pottinger/', '_blank')} />
                </Box>
            </Box>
            <Button className={classes.btn} onClick={handleClick}>
                Subscribe
            </Button>
            <Popover
                classes={{ root: 'subscribe' }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <div className={classes.subscribe}>
                    <HubspotSubscribe />
                </div>
            </Popover>
        </div >
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Toolbar>
                <AppBar position="fixed" className={classes.appBar}>
                    <Box display="flex" flexDirection="row">
                        <div className={classes.avatarAppBar}>
                            <Avatar alt="Julia Pottinger" src={require('../../content/assets/profile-pic-real.jpg')} className={classes.small} onClick={event => { navigate("/") }} />
                        </div>
                        <Box display="flex" flexDirection="row" className={classes.mediaContactListAppBar}>
                            <Box className={classes.mediaContactListItemAppBar}>
                                <YouTubeIcon onClick={event => window.open('https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1', '_blank', 'noopener noreferrer')} />
                            </Box>
                            <Box className={classes.mediaContactListItemAppBar}>
                                <TwitterIcon onClick={event => window.open('https://twitter.com/ailuj876', '_blank', 'noopener noreferrer')} />
                            </Box>
                            <Box className={classes.mediaContactListItemAppBar}>
                                <GitHubIcon onClick={event => window.open('https://github.com/jpott77', '_blank', 'noopener noreferrer')} />
                            </Box>
                            <Box className={classes.mediaContactListItemAppBar}>
                                <LinkedInIcon onClick={event => window.open('https://www.linkedin.com/in/julia-pottinger/', '_blank', 'noopener noreferrer')} />
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon fontSize='large' />
                        </IconButton>
                    </Box>
                </AppBar>
            </Toolbar>
            <nav aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Box>
                            {drawerSide}
                        </Box>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            // paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <Box>
                            {drawer}
                        </Box>
                    </Drawer>
                </Hidden>
            </nav>
        </div >
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.any,
};


export default ResponsiveDrawer;
